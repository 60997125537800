import CallingApp from '../images/CallingApp.png';

export default function Contact() {
    return (
        <div>
            <section className="section-bg1">
                <div className="container" data-aos="fade-up">
                    <div className="section-title-6">
                        <h2>Give it a try!</h2>
                        <p>We are not afraid of saying "NO" if we can't take it up. The team we build will be built for success.</p>
                    </div>
                    <div className="text-center">
                     <a href="#contact" className="btn-get-started-contact scrollto">Get in touch</a>
                   </div>
                </div>
            </section>
            <section id="contact" className="contact px-4">
                <div className="container" data-aos="fade-up">
                    <p className="contactUs">It's good to know you would like to partner with us.</p>
                    <div className="row mt-5 contact-flexDirection">
                        <div className="col-lg-8 mt-5 mt-lg-0">
                            <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className="php-email-form">
                                <input type="hidden" name="form-name" value="contact" />
                                <div className="row">
                                    <div className="col-md-6 form-group mt-3 mt-md-3">
                                        <input type="text" className="form-control" name="company name" id="company name" placeholder="Company Name" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group mt-3 mt-md-3">
                                        <input type="text" className="form-control" name="name" id="name" placeholder="Your Name" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group mt-3 mt-md-3">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-3">
                                    <textarea className="form-control" name="message" rows="5" placeholder="How can we help you ?" required></textarea>
                                </div>
                                <button type="submit">Send</button>
                            </form>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <img src={CallingApp} className="CallingApp" alt="contact" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
