

export default function Home() {
  return (
    <div>
      <section id="hero" class="d-flex align-items-center">
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 text-center">
              <h1>Your extended software development team</h1>
              <p>Teamextn builds you the right team to deliver software products successfully. We work with you on the design, development, deployment, and maintenance of your products. We see beauty in code and at the same time, understand things from the business side.</p>
            </div>
          </div>
          <div className="text-center">
            <a href="#contact" className="btn-get-started scrollto">Let’s talk</a>
          </div>
        </div>
      </section>
      <section className="section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Experience</h2>
            <p className="mx-auto">Every project fills a gap, it's never one-size-fits-all. Our diverse experience from working with startups to working with enterprises sized companies, lets us formulate the right technology stack, team structure, and the right process and communication models to achieve the goals.</p>
            <br /><br />
            <p className="mx-auto">Our pragmatic approach makes us choose the right technology stack during implementation. Just as an example if you are a startup requiring quick time to market we suggest you, Ruby/Rails. If there is a service that really needs to scale then we suggest going with Go or Java. For the most part, we adapt ourselves to the technology stack that is already in use unless there are compelling reasons to introduce new technology stacks.</p>
          </div>
        </div>
      </section>
      <section className="section-bg1">
        <div className="container" data-aos="fade-up">
          <div className="section-title-1">
            <h2>Breaking Stereotypes</h2>
            <p className="mx-auto">A lot of people that you will meet tell you "offshore didn't work for us...."</p>
            <br /><br />
            <p className="mx-auto">We have proved this wrong, time and again. We come in with a process and communication that we can take initiatives to the finish line. These are not tall claims rather we have delivered success multiple times in the past.
              We are definitely opinionated but we are humble at the same time. We live in the real world and we don't want to follow any processes and technologies dogmatically. We adapt and keep evolving. However, we don't "yes" to everything</p>
          </div>
        </div>
      </section>
    </div>
  )
}
