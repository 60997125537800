import React from "react"
import Header from "../components/Header"
import  Home from "./Home"
import Clients from "./Clients"
import Services from "./Services"
import Contact from "./Contact"
import About from "./About"
import Footer from "../components/Footer"

export default function Main() {
    return (
        <div>
            <Header />
            <Home />
            <Clients />
            <Services />
            <About />
            <Contact />
            <Footer />
        </div>
    )
}
