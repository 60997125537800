import React from "react";
import logo from '../images/logo.png';


export default function Header() {
    return (

        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid nav--me">
                <a className="navbar-brand" href="https://teamextn.com" id="home"><img src={logo} className="logo" alt="Logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item px-5">
                            <a className="nav-link active" aria-current="page" href="#hero">Home</a>
                        </li>
                        <li className="nav-item px-5">
                            <a className="nav-link" href="#clients">Clients</a>
                        </li>
                        <li className="nav-item px-5">
                            <a className="nav-link" href="#services">Services</a>
                        </li>
                        <li className="nav-item px-5">
                            <a className="nav-link" href="#about">About Us</a>
                        </li>
                        <li className="nav-item px-5">
                            <a className="nav-link" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>


    )
}
