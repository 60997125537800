import bigfuture from '../images/bigfuture.png';

export default function Clients() {
  return (
    <div id="clients">
      <section className="section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>We Work With</h2>
            <br /><br />
            <div class="center">
              <img src={bigfuture} className="clients" alt="bigfuture" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
