import React from "react";


export default function Footer() {
  return (

    <div class="footer">
        Teamextn © 2023 All rights reserved
    </div>


  )
}
