import Frame from '../images/Frame.png';
import DevIcon from '../images/DevIcon.png';
import DepIcon from '../images/DepIcon.png';

export default function Services() {
    return (
        <div>
            <section id="services" className="section-bg2">
                <div className="container" data-aos="fade-up">
                    <div className="shadow p-5 bg-body rounded services-boxShadow">
                        <div className="section-title-2">
                            <h2>Services</h2>
                            <p>We blend in and add value at different phases of your project. Depending on what stage your project is we can walk and start taking things to the next level with your product design, development and deployment. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-bg2 section-services">
                <div className="container" data-aos="fade-up">
                    <div className="shadow p-5 mb-5 bg-body rounded services-boxShadow">
                        <div className="row service-cols">
                            <div className="col-lg-7">
                                <div className="section-title-3">
                                    <h2>Design</h2>
                                    <p>We can do traditional UX design to starting from competitive analysis to Information Architecture to Visual Display to User Testing.If you are starting out and you have a basic design to reach out to your audience. We understand your thoughts and bring it to life.</p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img src={Frame} className="Frame" alt="services" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-bg2 section-devlopment">
                <div className="container" data-aos="fade-up">
                    <div className="shadow p-5 mb-5 bg-body rounded services-boxShadow">
                        <div className="row service-cols">
                            <div className="col-lg-7">
                                <div className="section-title-3">
                                    <h2>Development</h2>
                                    <p>We have rich development experience from working on monoliths to micro-service driven architectures. As developers our favorite development is Ruby. However, if there is a service or application that needs to be highly performant we choose technologies like Go. </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img src={DevIcon} className="DevIcon" alt="development" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-bg2 section-devlopment">
                <div className="container" data-aos="fade-up">
                    <div className="shadow p-5 mb-5 bg-body rounded services-boxShadow">
                        <div className="row service-cols">
                            <div className="col-lg-7">
                                <div className="section-title-3">
                                    <h2>Deployment</h2>
                                    <p>Depending on the architecture of your software product we will recommend you the right deployment environment for your application.We have the knowledge to deploying your monoliths to virtual machines to using IAAS for microservices to FAAS for event-driven architectures. </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img src={DepIcon} className="DepIcon" alt="deployment" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="d-flex align-items-center">
                <div class="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-12 mx-auto">
                            <div className="section-title-4">
                                <p className="mx-auto">A remote team of designers and software engineers taking a pragmatic approach to successfully deliver real-world software for their clients.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center"><a href="#contact" className="btn-get-started scrollto">Let’s talk</a></div>
                </div>
            </section> */}
        </div>
    )
}
