export default function About() {
    return (
        <div id="about">
          <section class="d-flex align-items-center">
            <div class="container" data-aos="fade-up">
              <div className="row">
                <div className="col-12 mx-auto">
                  <div className="section-title-4">
                    <p className="mx-auto">A remote team of designers and software engineers taking a pragmatic approach to successfully deliver real-world software for their clients.</p>
                  </div>
                </div>
              </div>
              <div className="text-center"><a href="#contact" className="btn-get-started scrollto">Let’s talk</a></div>
            </div>
          </section>

          <section className="section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title-5">
                <h2>Our Philosophy</h2>
                <p className="mx-auto">Every project that we have worked on in the past and the ones we take up in the future are unique in their own ways. Our approach to development and delivery is always focused on what is essential for the business.</p>
                <p className="mx-auto">We are a team based in India, and with our approach to communication and execution we have successfully delivered projects in the past and we are confident of delivering projects in the future as well. We would like our customers to think of us as their extended team and not consultants.
                </p>
              </div>
            </div>
          </section>
        </div>
    )
}
